import Certificates from "./component/certificates";
import Hero from "./component/hero";
import Languages from "./component/languages";
import Projects from "./component/projects";
import "./styles.css";
import { useEffect, useState } from "react";

export default function App() {
  const [socials, setSocials] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        "https://abhinavayas.github.io/abhraj.com-assets/json/social.json"
      );
      const data = await res.json();
      setSocials(data);
    };
    fetchData();
  }, []);

  return (
    <div className="app">
      <div className="fixed top-5 rounded-lg   bg-red-300">
        {socials.map((social) => {
          return (
            <img
              src={social.img}
              alt="social channels abhinav raj"
              className="w-10 rounded-full shadow-lg ml-1 mr-1 cursor-pointer my-2"
              onClick={() => window.open(social.url, "_blank")}
            />
          );
        })}
      </div>
      <Hero />
      <div className="h-[300px]"></div>
      <Projects />
      <div className="h-[300px]"></div>
      <Languages />
      <div className="h-[300px]"></div>
      <Certificates />
    </div>
  );
}
