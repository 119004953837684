import { motion } from "framer-motion";
import FloatBar from "./FloatBar";
import { useEffect, useState } from "react";

const Languages = () => {
  const [vw, setVW] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );

  useEffect(() => {
    function setDIM() {
      setVW(
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        )
      );
    }
    window.addEventListener("resize", setDIM);
  });

  const [lang, setLang] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        "https://abhinavayas.github.io/abhraj.com-assets/json/languages.json"
      );
      const data = await res.json();
      setLang(data);
    };
    fetchData();
  }, []);

  return (
    <div className="w-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-green-500 via-black-300 to-black ">
      <div className="flex justify-center ">
        <h1 className="text-white text-3xl my-10 font-black">
          Languages & Skills
        </h1>
      </div>
      <div
        className={
          vw > 600
            ? "grid w-full grid-cols-3 justify-items-center  gap-10 min-h-screen "
            : "grid w-full grid-cols-3 justify-items-center  gap-y-20"
        }
      >
        {lang.map((lang) => {
          return <FloatBar img={lang.img} star={lang.star} />;
        })}
      </div>
      <div className="h-20"></div>
    </div>
  );
};

export default Languages;
