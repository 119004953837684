import { motion, useViewportScroll, useTransform } from "framer-motion";
import { useEffect, useState } from "react";

const Projects = () => {
  const [vw, setVW] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );

  useEffect(() => {
    function setDIM() {
      setVW(
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        )
      );
    }
    window.addEventListener("resize", setDIM);
  });
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        "https://abhinavayas.github.io/abhraj.com-assets/json/projects.json"
      );
      const data = await res.json();
      setProjects(data);
    };
    fetchData();
  }, []);

  const { scrollYProgress } = useViewportScroll();
  const scale_scroll = useTransform(scrollYProgress, [0, 1], [0, 1]);

  const projectTile = (title, image, text, liveLink, githubLink, languages) => {
    return (
      <motion.div className="flex flex-col w-full " style={{}}>
        <div className="sticky flex top-0 w-full h-4xl justify-center bg-white">
          <h1 className={vw > 479 ? "text-3xl" : "text-2xl"}> {title} </h1>
        </div>
        <div className="w-full">
          <img style={{ margin: "auto" }} src={image} alt="project" />
        </div>
        <div className="">
          <p className="my-10">
            {text}
            <br />
            <br />
            {languages}
            <br />
            <br />
            {githubLink && (
              <a className="text-blue-600 font-bold text-lg" href={githubLink}>
                {" "}
                <u>{"Github >>>"} </u>{" "}
              </a>
            )}
            <br />
            {liveLink && (
              <a className="text-blue-600 font-bold text-lg" href={liveLink}>
                {" "}
                <u>{"Live >>>"} </u>{" "}
              </a>
            )}
          </p>
        </div>
      </motion.div>
    );
  };

  return (
    <div
      className={
        vw > 600
          ? "flex justify-between  bg-white mx-20"
          : "flex justify-between  bg-white mx-5"
      }
    >
      <div
        className={
          vw > 600
            ? "sticky top-0 left-0 flex items-center justify-center w-20  h-screen "
            : "sticky top-0 left-0 flex items-center justify-center w-5  h-screen "
        }
      >
        <div>
          <motion.div
            initial={{ rotate: 270 }}
            animate={{ y: [50, -50, -50, 50], scale: [1, 1.2, 1.2, 1] }}
            transition={{ duration: 3, repeat: Infinity }}
          >
            <h1 className={vw > 600 ? "text-6xl" : "text-1xl"}>PROJECTS</h1>
          </motion.div>
        </div>
      </div>
      <div
        className={
          vw > 600
            ? "flex w-full flex-col justify-around items-center bg-white  mx-20 gap-10"
            : "flex w-full flex-col justify-around items-center bg-white  mx-5 gap-20"
        }
      >
        {projects.map((project) => {
          return projectTile(
            project.title,
            project.image,
            project.text,
            project.liveLink,
            project.githubLink,
            project.languages
          );
        })}
      </div>
      <div
        className={
          vw > 600
            ? "sticky top-0 left-0 flex items-center justify-center w-20  h-screen"
            : "sticky top-0 left-0 flex items-center justify-center w-2  h-screen"
        }
      >
        <motion.div
          animate={{ scaleX: [1, 1.2, 1.2, 1] }}
          transition={{ duration: 3, repeat: Infinity }}
        >
          <div
            className={
              vw > 600
                ? "relative bg-blue-100 w-20 h-80 rounded-lg"
                : "relative bg-blue-100 w-2 h-80 rounded-lg"
            }
          >
            <motion.div>
              <motion.div
                style={{
                  scaleY: scale_scroll
                }}
              >
                <div className="absolute bg-blue-300 w-full h-80 rounded-lg"></div>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default Projects;
