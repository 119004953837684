import { motion } from "framer-motion";
import { useEffect, useState } from "react";
const FloatBar = ({ img, star }) => {
  const [vw, setVW] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );

  useEffect(() => {
    function setDIM() {
      setVW(
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        )
      );
    }
    window.addEventListener("resize", setDIM);
  });

  let x = Math.random() * 3;
  return (
    <motion.div className={vw > 1356 ? "w-2/3" : "w-20"}>
      <div
        className={
          vw > 1356
            ? "flex w-full rounded-lg bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-violet-200 via-sky-500 to-teal-400 drop-shadow-lg"
            : "flex justify-around flex-col w-full rounded-lg bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-violet-200 via-sky-500 to-teal-400 drop-shadow-lg"
        }
      >
        <div className={vw > 1356 ? "w-full h-full  " : ""}>
          <img
            className={
              vw > 1356
                ? "object-contain rounded-l-lg invert"
                : "h-full w-full object-contain rounded-t-lg invert"
            }
            src={img}
            alt="typescript"
          />
        </div>
        <div className={vw > 1356 ? "flex flex-col mx-10 h-20" : ""}>
          {vw > 1356 && <div className={vw > 600 ? "h-10" : ""}> </div>}
          <div
            className={
              vw > 1356
                ? "flex w-full  h-full gap-5 items-center justify-center mx-10"
                : "flex flex-col w-full gap-5 items-center justify-center my-5"
            }
          >
            <div className="h-5">
              <img
                className={
                  star > 0
                    ? "h-full object-contain rounded-lg"
                    : "h-full object-contain rounded-lg filter grayscale  contrast-200"
                }
                src="https://abhinavayas.github.io/abhraj.com-assets/assets/language/star.png"
                alt="typescript"
              />
            </div>
            <div className="h-5">
              <img
                className={
                  star > 1
                    ? "h-full object-contain rounded-lg"
                    : "h-full object-contain rounded-lg filter grayscale  contrast-200"
                }
                src="https://abhinavayas.github.io/abhraj.com-assets/assets/language/star.png"
                alt="typescript"
              />
            </div>
            <div className="h-5">
              <img
                className={
                  star > 2
                    ? "h-full object-contain rounded-lg"
                    : "h-full object-contain rounded-lg filter grayscale  contrast-200"
                }
                src="https://abhinavayas.github.io/abhraj.com-assets/assets/language/star.png"
                alt="typescript"
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default FloatBar;
