import "../style/hero.css";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const Hero = () => {
  const [vw, setVW] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );

  useEffect(() => {
    function setDIM() {
      setVW(
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        )
      );
    }
    window.addEventListener("resize", setDIM);
  });

  return (
    <div
      className={
        // mobile else laptop
        vw > 479
          ? "flex flex-row justify-between bg-white h-screen mx-20"
          : "flex flex-col justify-between bg-white h-screen mx-5 gap-8"
      }
    >
      <div
        className={
          vw > 479
            ? "flex items-center w-2/3 justify-start"
            : "flex items-center h-2/3 justify-start w-full"
        }
      >
        <div className="h-1/3">
          <motion.div
            animate={{ opacity: [0.3, 0.5, 0.5, 0.3, 0.3, 1, 1, 0.3] }}
            transition={{ duration: 5, repeat: Infinity }}
          >
            <h1 className="text-5xl break-words text-green-600">
              Escape The Limits
            </h1>
          </motion.div>
          <h3 className="text-2xl">
            <br />
            Myself, Abhinav Raj
          </h3>
        </div>
      </div>
      <div
        className={
          vw > 479
            ? "flex items-center w-1/3 justify-end"
            : "flex items-center h-1/3 justify-center w-full"
        }
      >
        <div className="w-2/3">
          <motion.div
            animate={{
              x: [0, 50, 50, 0, -50, -50, 0]
            }}
            transition={{ duration: 5, repeat: Infinity }}
            whileHover={{ x: 50 }}
          >
            <div className="bg-gradient-to-br from-green-600 to-blue-600 rounded-full">
              <motion.div
                initial={{ x: 0 }}
                animate={{
                  y: [
                    0,
                    -Math.round(vw / 20),
                    -Math.round(vw / 20),
                    0,
                    0,
                    -Math.round(vw / 10),
                    -Math.round(vw / 10),
                    0
                  ],
                  x: [0, 5, 5, 0]
                }}
                transition={{ duration: 5, repeat: Infinity }}
              >
                <img
                  src="https://abhinavayas.github.io/abhraj.com-assets/assets/profile/profile-transparent-bg.png"
                  alt="Abhinav Raj"
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
