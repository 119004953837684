import { useEffect, useState } from "react";

export default function Certificates() {
  const [certificates, setCertificates] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        "https://abhinavayas.github.io/abhraj.com-assets/json/certificates.json"
      );
      const data = await res.json();
      setCertificates(data);
    };
    fetchData();
  }, []);

  return (
    <div className="px-5 py-2">
      <h1 className="font-black text-center py-20 text-6xl "> Belongings </h1>
      <div className="flex flex-col gap-20 items-center ">
        {certificates.map((certificate) => {
          return (
            <img
              src={certificate.img}
              alt="certificate abhinav raj"
              className="w-full max-w-[800px]"
            />
          );
        })}
      </div>
    </div>
  );
}
